/** 
 * Start Bottom Footer 
 * These three css rules will make footer in React stick to bottom of the page at all times
*/
@media only screen and (max-width: 768px) {
    #root {
        display: block;
    }
}
/** End Bottom Footer */
