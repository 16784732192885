.bottom-carousel {
    margin: 20px 0 20px 0;
}

.inner-carousel {
    margin: 0 0 30px 0;
}

.carousel-heading {
    text-transform: uppercase;
    font-size: 21px;
    border-bottom: 1px solid rgb(228, 228, 228);
    display: flex;
    justify-content: space-between;
    font-family: "poppin-medium";
}

.slick-prev-custom
{
    position: absolute;
    top: -1.7em;
    right: 2.16em !important;
    left: auto !important;
    background: transparent;
    font-size: 20px;
}

.slick-next-custom
{
    position: absolute;
    top: -1.7em;
    right: .4em !important;
    left: auto !important;
    background: transparent;
    font-size: 20px;
}