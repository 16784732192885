.spaDetailList {
    margin-top: 50px;
}

.title-div {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.spaDetailListTitle {
    font-size: 35px;
    font-family: "poppin-semibold";
    color: var(--color-secondary);
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: -0.04em;
}

.title-div > span {
    position: relative;
    padding: 0em 20px;
    z-index: 1;
    background-color: var(--color-quinary);
}