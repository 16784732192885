.headerDiv
{
    position: relative;
}

.header
{
    background: var(--color-senary);
    width: 100vw;
    z-index: 13;
    transition: .3s;
    position: fixed !important;
    top: 0;
    left: 0;
    box-shadow: rgb(0 0 0 / 5%) 0px 5px 9px 0px;
}

.headerReplacement
{
    width: 100vw;
    background: var(--color-senary);
}

.header-title {
    z-index: 11;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s;
}

.header-title:hover .header-logo
{
    opacity: .9;
}

.header-title:hover .header-logo-title
{
    opacity: .9;
}

.header-content {
    z-index: 10;
}

.hamburger {
    display: none;
    cursor: pointer;
}

.bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    background-color: var(--color-basic);
    transition: 0.3s;
}

.nav-link {
    color: var(--color-basic) !important;
    font-family: "poppin-semibold";
    letter-spacing: 0.03em;
    cursor: pointer;
}

.nav-link:hover {
    color: var(--color-tertiary) !important;
}

.header-logo
{
    fill: var(--color-dark-grey);
    width: 10em;
    margin-right: .5em;
    transition: .3s;
}