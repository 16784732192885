/* Social Footer */
.social-media
{
    background: var(--color-quinary);
    margin-top: 5em;

}

.social-links {
    display: flex;
    align-items: center;
    padding: 1rem 0px;
    justify-content: center;
    box-shadow: rgb(0 0 0 / 5%) 0px -5px 9px 0px;
    margin-top: 20px;
    width: 100%;
}

.social-links a {
    font-size: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    width: 3rem;
    margin: 0px 0.6rem;
    cursor: pointer;
    color: var(--color-basic);
    line-height: 80px;
    position: relative;
    overflow: hidden;
    z-index: 10
}

.social-links a::after {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    transition: 0.5s;
    z-index: -10;
    transform: scale(0);
    border-radius: 50px;
}

/* Instagram CSS */
.spaInstagram::after {
    content: "";
    background: linear-gradient(
        45deg,
        #f09433 0%,
        #e6683c 25%,
        #dc2743 50%,
        #cc2366 75%,
        #bc1888 100%
    );
}

.spaInstagram:hover {
    color: #fff;
}

/* Facebook CSS */
.spaFacebook::after {
    content: "";
    background: linear-gradient(#17A9FD, #0165E1);
}

.spaFacebook:hover {
    color: #fff;
}

/* Waze CSS */
.spaWaze::after {
    content: "";
    background: #5dc4ff;
}

.spaWaze:hover {
    color: #fff;
}

/* Google Map CSS */
.spaGmap
{
    position: relative;
}

.spaGmap::after {
    content: "";
    background: #fff;
}

.spaGmap:hover {
    color: #fff;
    border: none;
}

.spaGmap:hover .spaGmap-plain {
    opacity: 0;
}

.spaGmap:hover .spaGmap-color {
    opacity: 1;
}

.spaGmap-plain
{
    font-size: 25px !important;
    position: absolute;
    z-index: 1;
    transition: .3s;
}

.spaGmap-color
{
    font-size: 42px !important;
    top: 9px;
    position: absolute;
    z-index: 2;
    opacity: 0;
    transition: .3s;
}

.spaWhatsapp {
    font-size: 22px !important;
}

.spaWhatsapp::after {
    content: "";
    background: #25D366;
}

.spaWhatsapp:hover {
    color: #fff;
}

/* When Hover Move Color to respective logo */
.social-links a:hover::after {
    transform: scale(1);
}

.resetHover {
    animation: resetFontColor 1s forwards;
}

.resetHover::after {
    animation: resetTransform 1s forwards;
}

@keyframes resetFontColor {
    0% {
        color: #fff;
    } 
    100% {
        color: var(--color-basic);
    }
}

@keyframes resetTransform {
    0% {
        transform: scale(1);
    } 
    100% {
        transform: scale(0);
    }
}

/* Optional Function
.social-links a:hover {
    transform:  translateY(-5px);
} */
