:root
{
  --color-basic: #72492F;
  --color-secondary: #8E6246;
  --color-tertiary: #A67A5D;
  --color-quaternary: #EEE8E4;
  --color-quinary: #F7F7F7;
  --color-senary: #FEFEFE;
  --color-light-gold: #D6B981;
  --color-dark-grey: #3d3a37;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
  
::-webkit-scrollbar-track {
  border: none;
  background: var(--color-quinary);
}

::-webkit-scrollbar-thumb {
  background: var(--color-tertiary);
}