@media only screen and (max-width: 405px) {
    .about-us-description {
        display: flex;
        font-family: "poppin-regular";
        font-size: 10px;
        align-items: center;
        text-align: justify;
    }

    .about-us-content {
        padding-top: 0px;
    }

    .hero-title > span {
        font-size: 20px;
        padding: 10px;
    }
}