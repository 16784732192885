.filtered-item {
    text-align: center;
    margin: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    height: 10em;

}

.filtered-item img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.services-detail {
    margin-top: .1em;
    display: flex;
    font-size: 15px;
    justify-content: center;
    white-space: nowrap;
    font-family: "poppin-regular";
}