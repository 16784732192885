@media only screen and (max-width: 768px) {
    .detail-title {
        font-size: 25px;
    }

    .price-list-heading
    {
        font-size: 15px;
    }

    .price-list-item
    {
        font-size: 14px;
    }

    .massage-description {
        font-size: 14px;
    }

    .detail-page-navigation {
        display: flex;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
    }

    .detail-page-navigation li {
        display: flex;
        flex-direction: row;
    }

    ul.detail-page-navigation li a {
        margin: 5px 2px;
        font-size: 12px;
    }

    .detail-page-navigation-link-active
    {
        font-size: 12px;
    }

    .detail-page-nav-seperator::after
    {
        font-size: 12px;
    }

    .thumbs-wrapper ul li{
        max-width: 20%;
        min-width: 50px;
        height: auto;
    }

    .carousel-content
    {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .carousel 
    {
        width: 100vw;
    }

    .carousel .thumbs-wrapper
    {
        overflow: scroll;
    }

    .carousel .thumbs-wrapper .thumbs
    {
        display: flex;
        flex-direction: row;
        width: 100vw;
    }
}

@media only screen and (max-width: 405px) {

    .thumbs-wrapper ul {
        display: flex;
        padding-left: 0px !important;
        margin-right: 0px !important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}