/** 
 * Start Bottom Footer 
 * These three css rules will make footer in React stick to bottom of the page at all times
*/
.footer
{
    width: 101vw !important;
    margin-top: auto;
}

html, body, #root {
    width: 100vw;
    height: 100vh;
}
  
#root {
    display: flex;
    flex-direction: column;
}
/** End Bottom Footer */
