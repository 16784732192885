.maintenanceDiv
{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--color-basic);
    position: relative;
}

.maintenanceTitle 
{
    font-weight: 700;
    font-size: 30px;
}

.maintenanceIcon
{
    position: absolute;
    font-size: 15em;
    opacity: .1;
}