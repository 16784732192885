body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-quinary) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img 
{
  -webkit-user-drag: none;
}

@font-face {
  font-family: "poppin-light";
  src: local("Poppins-Light"), url("fonts/Poppins/Poppins-Light.ttf") format("truetype");
}

@font-face {
  font-family: "poppin-extrabold";
  src: local("Poppins-ExtraBold"), url("fonts/Poppins/Poppins-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "poppin-semibold";
  src: local("Poppins-SemiBold"), url("fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "poppin-medium";
  src: local("Poppins-Medium"), url("fonts/Poppins/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "poppin-light";
  src: local("Poppins-Light"), url("fonts/Poppins/Poppins-Light.ttf") format("truetype");
}

@font-face {
  font-family: "poppin-regular";
  src: local("Poppins-Regular"), url("fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "robecha";
  src: local("RobechaDaniera"), url("fonts/Robecha/RobechaDaniera-mLB2j.ttf") format("truetype");
}