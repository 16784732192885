@import "react-responsive-carousel/lib/styles/carousel.min.css";

.inner {
    margin: 3em 3em 0;
}

.detail-page-navigation {
    display: flex;
    align-items: center;
    list-style: none;
    justify-content: center;
}

.detail-page-navigation li {
    display: inline-block;
}

ul.detail-page-navigation li a {
    margin: 5px 2px;
    text-decoration: none;
    color: rgb(56, 56, 56);
    font-size: 15px;
    transition: .3s;
    font-family: "poppin-medium";
}

.detail-page-navigation-link-active {
    font-family: "poppin-semibold";
}

.detail-page-navigation li a:hover {
    color: var(--color-secondary);
}

.detail-page-nav-seperator::after
{
    content: " /";
    color: rgb(56, 56, 56);
    font-size: 15px;
}

.carousel-content {
    padding: 2em;
}

.carousel .slide img {
    width: 90%;
    vertical-align: top;
    border: 0;
    height: 550px;
    object-fit: cover;
    padding-bottom: 0px;
}

.detail-content {
    padding: 2em;
    margin-bottom: 20px;
}

.detail-title {
    display: flex;
    justify-content: center;
    font-size: 30px;
    letter-spacing: 0.05em;
    transition: .3s;
    color: var(--color-basic);
    font-family: "robecha";
    font-weight: bold;
}

.massage-description {
    margin-top: 20px;
    border-top: 0.1rem solid rgb(221, 221, 221);
    padding-top: 1em;
    font-size: 17px;
    letter-spacing: 0.05px;
    transition: .3s;
    text-align: justify;
    padding-bottom: 20px;
}

.massage-description div {
    font-family: "poppin-regular";
}

.descReadAction {
    font-family: "poppin-regular";
}

.price-list {
    border-width: 1px;
    border-style: solid;
    border-color: rgb(238, 238, 238);
    padding-left: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
    padding-right: 20px;
}

.price-list-common {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 100%;
}

.duration-heading span, .price-heading span{
    font-family: "poppin-semibold" !important;
}


.price-list-heading 
{
    font-weight: 600;
    text-transform: uppercase;
    font-size: 18px;
    transition: .3s;
    color: var(--color-basic);
}

.price-list-item
{
    font-size: 17px;
    transition: .3s;
}

.price-list-item-children
{
    width: 50%;
    font-family: "poppin-regular";
}

.detail-page-navigation-link-active
{
    color:var(--color-tertiary) !important;
    cursor: default;
    font-size: 15px;
}

.carousel .thumb
{
    width: 50px !important;
    height: 50px !important;
}

.carousel .thumb img
{
    height: 100%;
    object-fit: cover;
}

.carousel .thumb.selected, .carousel .thumb:hover {
    border: 3px solid var(--color-secondary) !important;
}

.modal
{
    height: 100vh !important;
}

.modalImgDiv
{
    height: fit-content;
}

.modalImgDiv img
{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.btn-close
{
    outline: none;
    transition: .3s;
}

.btn-close:hover
{
    color: var(--color-secondary) !important;
}