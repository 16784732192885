.splashDiv
{
    width: 100vw;
    height: 100vh;
    transition: .3s;
    background: var(--color-senary);
    z-index: 99999;
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: splashIntro 3s forwards;
}

.splashLogo {
    width: 20vw; 
    animation: fadeIn 1s forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes splashIntro {
    0% {
        overflow: hidden;
        opacity: 1;
    }
    95% {
        opacity: 1;
    }
    100% {
        overflow: auto;
        opacity: 0;
    }
}

.disableScroll
{
    overflow: hidden !important;
}

.hideSplash
{
    z-index: -10;
    transition: .3s;
    opacity: 0;
}