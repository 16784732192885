@media only screen and (max-width: 768px) {

    .hero-content {
        padding-left: 20px;
        padding-right: 20px;
        position: absolute;
        width: 75vw;
        border-radius: 3px;
        display: flex;
    }
}

@media only screen and (max-width: 405px) {

    .heroBannerDiv img {
        width: 100vw;
        height: 50vh;
    }

    .hero-content {
        padding-left: 20px;
        padding-right: 20px;
        position: absolute;
        width: 90vw;
        border-radius: 3px;
        display: flex;
    }
}