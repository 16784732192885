.heroBannerDiv
{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    position: relative;
}

.heroBannerDiv img
{
    width: 100vw;
    height: 60vh;
    filter: brightness(50%);
    object-fit: cover;
}
