.contact-container {
    background: var(--color-senary);
    display: flex;
    margin-top: 50px;
    align-items: center;
    flex-direction: column;
    padding: 20px;
}

.contact-us-title {
    font-size: 35px;
    letter-spacing: -0.04em;
    font-family: "poppin-semibold";
    text-transform: uppercase;
    color: var(--color-secondary);
}

.form-label {
    margin-bottom: 2.5px;
    font-family: "poppin-regular"
}

.form-control {
    font-family: "poppin-regular";
}

.form-content {
    padding: 0 40px 0;
}

.submit-button {
    background: var(--color-secondary)!important;
    border-color: var(--color-secondary)!important;
    opacity: .8;
}

.submit-button span {
    font-size: 15px;
    font-family: "poppin-semibold";
}

.submit-button:hover {
    color: var(--color-basic);
    opacity: 1;
}

.contact-us-snackbar
{
    background: transparent !important;
}

.contact-us-snackbar div
{
    background: var(--color-senary) !important;
    color: var(--color-secondary);
}

.submit-spinner
{
    margin-right: .5em;
}


/* .contact-us-link:after,
.contact-us-link:before {
  border: 1px solid rgb(247, 247, 247);
  bottom: 0px;
  content: "";
  display: block;
  margin: 0 auto;
  position: relative;
  width: 0;
}

.contact-us-link:hover:after,
.contact-us-link:hover:before {
  border-color: gold;
  transition: width 350ms ease-in-out;
  width: 80%;
} */