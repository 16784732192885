/* Copyright */
.copyright {
    padding: 20px 30%;
    background: var(--color-secondary);
    flex-direction: column;
    color: var(--color-quinary);
    width: 100%;
    transition: .3s;
}

.copyright-wrapper {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: center;
}

.copyright-words {
    padding-top: 0.5px;
    line-height: 20px;
    font-family: "poppin-light";
    text-align: center;
}

.copyright-content {
    display: flex;
    align-items: center;
    font-size: .8em;
}

.trademark {
    margin-left: 4px;
    height: 20px;
    width: 20px;
}