
.spaDetailCont
{
    margin: .5em;
    background: transparent;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: .3s ease-in-out;
    box-shadow: 5px 5px 5px rgba(0,0,0,.2);
    position: relative;
    animation: fadeInImg 1s ease;
}

@keyframes fadeInImg {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

.spaDetailCont img {
    width: 100%;
    height: 13em;
}

.spaDetailCont:hover, .spaDetailCont:focus
{
    transform: scale(1.05);
}

.spaDetailThumbnail
{
    border-radius: 5px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.spaDetailTitle
{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    color: var(--color-quaternary);
    border-radius: 5px;
    padding: 0 0 10px 10px;
    background: linear-gradient(0deg, var(--color-basic) 0%, rgba(0,0,0,0) 100%);
    font-family: "poppin-semibold";
    font-size: 14px;
}

.shimmer
{
    width: 100% !important;
    border-radius: 5px;
}