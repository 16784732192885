.descReadAction
{
    cursor: pointer;
    font-weight: 600;
    color: var(--color-secondary);
    transition: .3s;
    opacity: .8;
}

.descReadAction:hover 
{
    opacity: 1;
}
