@media only screen and (max-width: 768px) {
    .copyright-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        vertical-align: middle;
    }
    
    .copyright-words {
        line-height: 15px;
    }
    
    .copyright-content {
        font-size: .6em;
    }
}
