@media only screen and (max-width: 768px) {
    .hamburger {
        display: block;
        z-index: 13;
    }

    .hamburger.active .bar:nth-child(2){
        opacity: 0;
    }

    .hamburger.active .bar:nth-child(1){
        transform: translateY(8px) rotate(45deg);
    }

    .hamburger.active .bar:nth-child(3){
        transform: translateY(-8px) rotate(-45deg);
    }

    .navbar-links {
        position: fixed;
        left: 0;
        top: -100px;
        gap: 0;
        flex-direction: column;
        background-color: var(--color-quinary);
        width: 100%;
        height: 0vh;
        text-align: center;
        transition: .8s ease;  
        z-index: -5 !important;
        opacity: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
    }

    .nav-link
    {
        width: 100%;
        padding: 0 !important;
        opacity: .5;
        transition: .3s;
    }

    .nav-link:hover, .nav-link:focus
    {
        opacity: 1;
    }

    .navbar-links.active {
        top: 0px;
        opacity: 1;
        z-index: 8;
        height: 100vh !important;
    }

    .navbar-expand .navbar-nav {
        flex-direction: column!important;
    }

    .header-logo
    {
        fill: var(--color-dark-grey);
        width: 11em;
        margin-right: .5em;
    }

    .activeNav
    {
        font-weight: 600 !important;
        opacity: 1 !important;
    }
}