.hero-about-us {
    display: flex;
    margin-top: 40px;
    z-index: 11;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 20px;
    flex-wrap: wrap;
    background-color: var(--color-senary)
}

.hero-title {
    font-family: "poppin-semibold";
    font-size: 35px;
    font-weight: bold;
    position: relative;
    width: 100%;
    text-align: center;
    color: var(--color-basic);
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-title > span {
    position: relative;
    padding: 20px;
    z-index: 1;
    background-color: var(--color-senary);
}

.hero-title::after {
    content: " ";
    position: absolute;
    left: 0;
    top: 50%;
    border-bottom: 2px solid var(--color-basic) !important;
    opacity: .5;
    width: 100%;
}

.about-us-content {
    padding-top: 20px;
}

.about-us-description {
    display: flex;
    font-family: "poppin-regular";
    font-size: 15px;
    align-items: center;
    text-align: justify;
}