.whatsappBtnDiv {
    position: fixed;
    z-index: 11;
    right: 20px;
    bottom: 20px;
    /* animation: decline 2s ease; */
}

.whatsappBtnDivAlternate {
    position: fixed;
    z-index: 11;
    right: 20px;
    bottom: 150px;
    animation: riseUp 2s ease;
}

.whatsappBtnDiv > a > svg{
    height: 60px;
    width: 60px;
}

.whatsappBtn {
    opacity: 1;
    transition: .3s;
}

.whatsappBtn:hover {
    opacity: .9;
}

@keyframes riseUp {
    from {
        bottom: 20px;
    }
    to {
        transform:(translateY(100%));
    }
}

@keyframes decline {
    from {
        bottom: 140px;
    } to {
        transform: (translateY(100%));
    }
}